'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.locations.controller:LocationsCtrl

 # @description

###
class LocationsCtrl
  ### @ngInject ###
  constructor:(
    $state
    $scope
  ) ->
    @currentTab = $state.current.data.selectedTab

    $scope.$on '$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) =>
      @currentTab = toState.data.selectedTab

angular
  .module('mundoAdmin.locations')
  .controller 'LocationsCtrl', LocationsCtrl
